import request from '../utils/request.js'

//获取工单列表
export function workList(data) {
    return request({
        url: `/workOrder/list`,
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//客服提交
export function workUpload(data) {
    return request({
        url: `/workOrder/explain`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}