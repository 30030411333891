<template>
  <div class="bgcard">
    <div class="">
      <div class="title">
        <div class="lines"></div>
        <p>{{ $t("lang.Workorderdetails") }}</p>
      </div>
      <div v-for="(item, index) in workdata.description" :key="index">
        <div class="msg" v-if="item.category == 1">
          <img v-if="workdata.img" :src="workdata.img" />
          <img v-else src="../../../../assets/imgs/yhzcl.png" />
          <div class="msgcontent">
            <p>
              <span>{{ workdata.name ? workdata.name : workdata.email }}</span
              ><span>{{ item.time }}</span>
            </p>
            <div class="msgcard">{{ item.description }}</div>
          </div>
        </div>
        <div v-if="item.category == 1 && item.file">
          <div class="msg" v-for="(items, indexs) in item.file" :key="'a_' + indexs">
            <img v-if="workdata.img" :src="workdata.img" />
            <img v-else src="../../../../assets/imgs/yhzcl.png" />
            <div class="msgcontent img">
              <p>
                <span>{{ workdata.name ? workdata.name : workdata.email }}</span
                ><span>{{ item.time }}</span>
              </p>
              <div class="msgcard">
                <img :src="items" @click="seeimg(items,index,indexs)" :ref="'imgs_'+index+'s_'+indexs" />
              </div>
            </div>
          </div>
        </div>
        <div class="msg customer" v-if="item.category == 2">
          <div class="msgcontent">
            <p>
              <span>{{ item.time }}</span
              ><span>{{ $t("lang.Univisocloudliveservice") }}</span>
            </p>
            <div class="msgcard" style="background: #307b37; color: #ffffff">
              {{ item.description }}
            </div>
          </div>
          <img :src="user.img" />
        </div>
				<div class="msg customer" v-if="item.category == 2&& item.file">
					<div>
						<div class="msg" v-for="(items, indexs) in item.file" :key="'a_' + indexs">
						  <div class="msgcontent img">
						    <p>
						      <span>{{ item.time }}</span
						      ><span>{{ $t("lang.Univisocloudliveservice") }}</span>
						    </p>
						    <div class="msgcard">
						      <img :src="items" @click="seeimg(items,index,indexs)" :ref="'imgs_'+index+'s_'+indexs" />
						    </div>
						  </div>
						  <img :src="user.img" />
						</div>
					</div>
				</div>
      </div>
    </div>
    <div class="line" ref="chatContainer">
      <div class="lines"></div>
      <p>{{ $t("lang.reply") }}</p>
      <div class="lines"></div>
    </div>
    <div class="question">
      <div class="describe">
        <p>{{ $t("lang.Answerquestions") }}:</p>
        <textarea
          v-model="value"
          maxlength="256"
          :placeholder="$t('lang.Pleaseinputthecontent')+'（'+$t('lang.Upcharacters')+'）'"
        ></textarea>
      </div>
			<div class="enclosure">
			  <p>{{ $t("lang.enclosure") }}:</p>
			  <el-upload
			    class="upload-demo"
			    :action="url"
			    :before-remove="beforeRemove"
			    :limit="3"
			    :headers="headers"
			    list-type="picture"
			    :on-exceed=" () => { $message.error($t('lang.Themaximumnumber'));}"
			    :file-list="fileList"
			    :on-success="success"
			    accept=".jpg,.jpeg,.png"
			    :before-upload="beforeUpload"
					 :on-remove='imgremove'
			  >
			    <el-button size="small" type="primary" style="font-size: 16">+ {{ $t("lang.Addfile") }}</el-button>
			  </el-upload>
			</div>
      <div class="btn">
        <button @click="Submit">{{ $t("lang.submit") }}</button>
        <button @click="retuen">{{ $t("lang.return") }}</button>
      </div>
    </div>
		<div class="bigimg">
			<el-dialog :visible.sync="detailsmsg" top="3rem" :width="wids" :show-close="false">
			   <div class="bigimgsa">
					 <img :src="bigimgs" />
				 </div>
			</el-dialog>
		</div>
  </div>
</template>

<script>
import * as workmsg from "@/api/manage.js";
import * as works from "@/api/worklist.js";
import * as baseURL from "@/utils/request.js";
export default {
  data() {
    return {
      userid: this.$route.query.id,
      page: this.$route.query.page,
      user: "",
      workdata: "",
      value: "",
      imgurl: "",
			url: "",
			tokens:'',
			fileList:[],
			file:[],
			filessa:true,
			detailsmsg:false,
			wids:'',
			bigimgs:''
    };
  },
  mounted() {
		this.url = baseURL.upload;
		let accessToken = sessionStorage.getItem("token");
		this.tokens = JSON.parse(accessToken);
    let use = sessionStorage.getItem("user");
    this.user = JSON.parse(use);
    this.imgurl = baseURL.imgUrl;
    this.getList();
  },
  updated() {
    var scrollTop = this.$el.querySelector(".line");
        // console.log(scrollTop.scrollHeight)
        console.log( scrollTop.scrollHeight,this.$refs.chatContainer.getBoundingClientRect().top); // 查看打印的值是否有变化 如果有 则说明滚滚动条在这个标签中
        scrollTop.scrollTop =this.$refs.chatContainer.getBoundingClientRect().top // 可以尝试下 滚动滚动条。一直在底部则可以设置成功
  },
	computed: {
	  headers() {
	    return {
	      Authorization: this.tokens, // 直接从本地获取token就行
	    };
	  },
	},
  methods: {
    scolls() {
      window.addEventListener(
        "scroll",
        () => {
          var scrollTop = this.$el.querySelector(".line");
          // console.log(scrollTop.scrollHeight)
          console.log(scrollTop.scrollTop); // 查看打印的值是否有变化 如果有 则说明滚滚动条在这个标签中
          // scrollTop.scrollTop = scrollTop.scrollHeight // 可以尝试下 滚动滚动条。一直在底部则可以设置成功
        },
        true
      );
    },
    //获取工单数据
    async getList() {
      let res = await workmsg.Workdata({ id: this.userid });
      this.workdata = res.data;
      this.scolls();
    },
		//上传成功之后
		success(res) {
		  if (res) {
		    this.$message.success(this.$t("lang.Uploadsucceeded"));
		    this.file.push(res.data.path);
		  }
		},
		//删除文件之前
		beforeRemove(file) {
			if(this.filessa){
				return this.$confirm(this.$t("lang.Areyousureyouwanttoremove")+`${file.name}？`);
			}
		},
		//删除文件
		imgremove(file){
			if(this.filessa){
				let sk=this.file.indexOf(file.response.data.path)
				this.file.splice(sk,1)
			}
		},
		//上传文件之前
		beforeUpload(file) {
			this.filessa=file
		  let isIMG =
		    file.name.split(".")[file.name.split(".").length - 1] === "jpg" ||
		    file.name.split(".")[file.name.split(".").length - 1] === "jpeg" ||
		    file.name.split(".")[file.name.split(".").length - 1] === "png";
				const isLt10M = file.size / 1024 / 1024 < 10;
		  if (!isIMG) {
		    this.$message.error(
		      this.$t("lang.Uploadavatarimagecanonlybe") +
		        "JPG、PNG、JPEG" +
		        this.$t("lang.format")
		    );
				this.filessa=false
				return this.filessa
		  }
		},
    //提交
    Submit() {
      let data = {
        description: {
          description: this.value,
          img: this.user.img,
        },
        id: this.userid,
      };
			if (this.file.length > 0) {
			  data.description.file = this.file;
			}
      works.workUpload(data).then((res) => {
        this.$message.success(this.$t("lang.Submitted"));
        this.value = "";
				this.fileList=[],
				this.file=[],
        this.getList();
        /* this.$router.push({
					path:"workmsg"
				}); */
      });
    },
		seeimg(img,ind,inds){
			this.detailsmsg=true
			this.bigimgs=img
			let sk='imgs_'+ind+'s_'+inds
			let ims=this.$refs[sk]
			this.wids=ims[0].naturalWidth+'px'
			if(ims[0].naturalWidth>1400){
				this.wids=14+'rem'
			}
			if(ims[0].naturalWidth<200){
				this.wids=2+'rem'
			}
		},
    //返回
    retuen() {
      this.$router.push({
        path: "WorkMsg",
        query: { page: this.page },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.19rem 0.17rem;
  border-radius: 0.02rem;
  background: #222628;
  padding: 0.39rem 0.34rem 0.7rem 0.38rem;
  border-radius: 0.02rem;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  .lines {
    width: 0.04rem;
    height: 0.3rem;
    background: #b2b2b2;
    border-radius: 0.02rem;
    margin-right: 0.11rem;
  }
  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
  }
}
.msg {
  display: flex;
  margin-top: 0.29rem;
  img {
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
    margin-right: 0.09rem;
  }
  .msgcontent {
    width: 5.07rem;
    p {
      display: flex;
      justify-content: space-between;
      margin-top: 0.04rem;
      margin-bottom: 0.1rem;
      span {
        font-size: 0.14rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffff;
      }
    }
    .msgcard {
      background: #161a1c;
      border-radius: 0.04rem;
      padding: 0.16rem 0.15rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.2rem;
      color: #fff;
      word-break: break-all;
    }
  }
  .img {
    width: 3.5rem;
    .msgcard {
      padding: 0.16rem 0.15rem;
      box-sizing: border-box;
      border-radius: 0;
      img {
        width: 3.2rem;
        height: auto;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
.customer {
  margin-top: 0.29rem;
  justify-content: flex-end;
  img {
    margin-right: 0px;
    margin-left: 0.09rem;
  }
}
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  margin-top: 0.36rem;
  .lines {
    width: 6.8rem;
    height: 0.01rem;
    background: #4c4c4c;
  }
  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin: 0px 0.13rem;
  }
}
.question {
  margin-top: 0.36rem;
  p {
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin-top: 0.22rem;
    margin-right: 0.16rem;
  }
  .describe {
    display: flex;
    margin-bottom: 0.15rem;
    textarea {
      width: 6.56rem;
      height: 1rem;
      outline-style: none;
      border: none;
      background: #161a1c;
      border-radius: 0.04rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
      line-height: 0.35rem;
      text-indent: 0.16rem;
      padding-top: 0.12rem;
    }
  }
	.enclosure {
	  width: 6.56rem;
	  // display: flex;
	  p {
	    height: .54rem;
	    line-height: .54rem;
	    margin-top: 0px;
	  }
	  button {
	    // margin-left: 38px;
	    // width: 131px;
	    height: .54rem;
	    font-size: .16rem;
	    outline-style: none;
	    border: none;
	    background: #1061c5;
	    border-radius: 4px;
	  }
		/deep/ .el-upload-list{
			width: 3rem;
		}
	}
  .btn {
    margin-top: 0.52rem;
    margin-left: 0.9rem;
    button {
      width: 1.31rem;
      height: 0.54rem;
      border: none;
      outline-style: none;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      &:first-of-type {
        background: #307b37;
        color: #ffffff;
        margin-right: 0.16rem;
      }
      &:last-of-type {
        color: #307b37;
        background: #222628;
        border: 1px solid #307b37;
      }
    }
  }
}
/deep/ .bigimg{
	.el-dialog__header{
		display: none;
	}
	.el-dialog__body{
		padding: .15rem;
	}
	.bigimgsa{
		width: 100%;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		img{
			max-width: 100%;
		}
	}
}
</style>
